:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
}

#root {
  width: 100vw !important;
}

a {
  color: var(--dark) !important;
  text-decoration: none !important;
}

a:hover {
  text-decoration: underline !important;
}

.fullHeight {
  /*  display: flex;*/
  /*  flex-flow: column;*/
  height: 100%;
}

.footer {
  /*  position: absolute;*/
  /*  bottom: 0px;*/
}

.flex {
  display: flex;
}
.flexColumn {
  flex-direction: column;
}
.JCSpaceBetween {
  justify-content: space-between;
}
.JCSpaceAround {
  justify-content: space-around;
}
.JCEnd {
  justify-content: flex-end;
}
.w100 {
  width: 100%;
}

.AIBaseline {
  align-items: baseline;
}

.marginBottomSmall {
  margin-bottom: 1.5rem;
}
.marginTopSmall {
  margin-top: 1.5rem;
}
.marginBottomMedium {
  margin-bottom: 3rem;
}
.marginTopMedium {
  margin-top: 3rem;
}

h2 {
  font-family:
    Helvetica Neue,
    sans-serif;
  font-size: 1.2857142857em !important;
  line-height: 1.3em;
  font-weight: 500 !important;
}

.grayFont {
  color: #535353;
}

#root {
  width: 50vw;
}

.mainContainer {
  display: flex;
  flex-direction: row;
  min-height: 100%;
  min-width: 100%;
}
.leftContainer {
  background: #ffffff;
  justify-content: flex-end;
  padding-left: 5%;
  padding-right: 5%;
}
.rightContainer {
  background: #f9f9f9;
  border-left: 0.25px solid #dadada;
  justify-content: flex-start;
  padding-left: 5%;
}

.wrapper {
  float: left;
  padding-top: 4em;
}

.fixPadding {
  float: left;
  padding-bottom: 1em;
}

.newClass {
  background: #f9f9f9;
  justify-content: flex-start;
  padding: 1em;
}

.horizontalRuler {
  border-top: 1px solid #dadada;
}

.productImg {
  width: 4.6em;
  height: 4.6em;
  border-radius: 8px;
}

.productImgContainer {
  border: solid 1px #dadada;
  border-radius: 8px;
  width: 4.6em;
  height: 4.6em;
}

.productQuantity {
  position: relative;
  font-size: 0.8571428571em;
  font-weight: 500;
  line-height: 1.75em;
  white-space: nowrap;
  text-align: center;
  border-radius: 1.75em;
  background-color: rgba(114, 114, 114, 0.9);
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 1.75em;
  height: 1.75em;
  padding: 0 0.5em;
  right: -3.75em;
  top: -6.25em;
  z-index: 3;
}

.productTable {
  margin-top: 0.75em;
  margin-bottom: 1.5em;
  width: 100%;
}

.productTable tr td {
  padding-top: 1em;
}

.productTable tr td:nth-child(2) {
  padding: 0 5%;
}

.productPriceTable {
  width: 100%;
}

.productPriceTable tr:first-child td:first-child {
  width: 60%;
}

.productPriceTable tr td {
  padding-top: 0.75em;
}
.productPriceTable tr td:last-child {
  text-align: right;
}
.productPriceTable tr:last-child td {
  padding-bottom: 0.75em;
}

.productTable td.truncate-md {
  width: 100%;
}

/*.productPriceTable tr:last-child td:last-child {*/
.productPriceTable tr td:last-child {
  color: #717171;
  font-size: 0.8571428571em;
}

.priceGridContainer {
  display: grid;
  grid-template-columns: 65% 35%;
  grid-template-areas:
    "totalLabelRow totalRow"
    "taxRow totalRow";
  padding-top: 0.75em;
}
.totalLabelRow {
  grid-area: totalLabelRow;
  color: #323232;
  font-size: 1.1428571429em;
}
.taxRow {
  grid-area: taxRow;
  font-size: 0.8571428571em;
  color: #717171;
}
.totalRow {
  grid-area: totalRow;
  justify-self: end;
  align-self: center;
  font-size: 1.7142857143em;
  font-weight: 500;
  letter-spacing: -0.04em;
  line-height: 1em;
}

.form-check-label {
  font-weight: bold;
}

.policy-list__item {
  float: left;
  font-size: 0.8571428571em;
  margin-right: 1em;
  margin-bottom: 0.5em;
}

.box {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 1.1428571429em;
}

.boxTop {
  border-radius: 5px 5px 0 0;
}
.boxMiddle {
  border-radius: 0px;
}
.boxBottom {
  border-radius: 0 0 5px 5px;
}

.smallBox {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 0.9em;
}

.btn-bold {
  font-weight: bold;
}

.wizardContainer {
  font-size: 0.8571428571em;
  color: #737373;
  height: auto;
}

.wizardLink {
  border: none;
  background: none;
}

.referredContainer {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  height: calc(2.5rem + calc(var(--bs-border-width) * 2)) !important;
  display: flex;
  align-items: center;
  width: 441.344px;
  margin: 0px !important;
}

.referredContainerActive {
  height: calc(5.5rem + calc(var(--bs-border-width) * 2)) !important;
}

.referredContainer div:nth-child(1) {
  padding-right: 0;
  /*  color: #333;*/
}

.referredContainer div:nth-child(2) {
  padding-left: 0;
  color: #333;
}
.referredContainer div:nth-child(3) {
  font-size: 0.8571428571em;
  color: #333;
}

.referredButton {
  border: none;
  background: none;
}

.form-control,
.form-select {
  padding: 0 0.75rem !important;
  padding-top: 1.3rem !important;
}

.form-floating .form-control,
.form-floating .form-select {
  height: calc(2.5rem + 2px) !important;
  min-height: calc(2.5rem + 2px) !important;
  font-size: 0.9rem;
}

input:focus,
select:focus {
  border: 2px solid #333 !important;
  box-shadow: none !important;
  outline: 0 none !important;
}

.mb-1em {
  margin-bottom: 0.9em;
}

.someClass {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #555;
  margin-bottom: 0;
}

.transparent {
  background: transparent;
  border: none;
}

.paymentContainer {
  overflow-x: hidden;
  width: 100%;
  padding: 1.1428571429em;
  box-sizing: border-box;

  .form-floating > .form-control {
    height: calc(1.5rem + 2px) !important;
  }
  .form-floating > .form-select {
    height: calc(1.5rem + 2px) !important;
  }
}

.creditcardLabel {
  font-weight: bold;
  color: #434343;
}

.smallTable {
  font-size: 0.75em;
}

.form-floating > .form-control {
  height: calc(2.5rem + 2px) !important;
}

.form-floating > .form-select {
  height: calc(2.5rem + 2px) !important;
}

.paymentContainer {
  .payment-form-select {
    padding: 8px 0.75rem !important;
  }

  .payment-form {
    padding: 8px 0.75rem !important;
  }
}

#tokenExIframeDiv {
  max-height: 40px;
  overflow: "hidden" !important;
  width: 90%;
}

/* Media query for tablet screens */
@media screen and (max-width: 1024px) {
  .productTable td.truncate-md {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/* Media query for laptop screens */
@media screen and (max-width: 768px) {
  .productTable td.truncate-md {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.main-color {
  background-color: #d86b59 !important;
  border: #d86b59 !important;
}

.text-main-color {
  color: #d86b59 !important;
}

.text-seconday-color {
  color: #2c414c !important;
}

.secondary-color {
  background-color: #2c414c !important;
  border: #2c414c !important;
}

.oola-main-color {
  background-color: #78ccf6 !important;
  border: #78ccf6 !important;
}
.oola-main-text-color {
  color: #78ccf6 !important;
}

.main-color:hover {
  background-color: #c37466 !important;
  border: #c37466 !important;
}

.secondary-color:hover {
  background-color: #39515d !important;
  border: #39515d !important;
}

.image-cut-bottom {
  margin-bottom: -40px !important;
}

.user-login-url-form {
  width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 2;
}

/* Override Bootstrap's default validation styles */
.form-control.is-valid {
  border-color: #198754 !important;
  background-image: none !important;
}

.form-control.is-invalid {
  border-color: #dc3545 !important;
  background-image: none !important;
}

/* Remove all validation icons */
.form-control:valid,
.form-control:invalid {
  background-image: none !important;
}

/* Ensure invalid state ALWAYS takes precedence */
.form-control.is-valid.is-invalid,
.form-control.is-invalid {
  border-color: #dc3545 !important;
  background-image: none !important;
}

/* Remove success state when invalid */
.form-control.is-invalid.is-valid {
  border-color: #dc3545 !important;
}

/* Reset any previous validation styles when focused */
.form-control:focus {
  border-color: #333 !important;
  box-shadow: none !important;
}

.paymentContainer {
  overflow-x: hidden;
  width: 100%;
  padding: 1.1428571429em;
  box-sizing: border-box;
}

/* Mobile adjustments */
@media screen and (max-width: 576px) {
  .paymentContainer {
    padding: 1em;
    width: 100%;
    max-width: 100vw;
  }

  #tokenExIframeDiv {
    width: 100% !important;
    margin: 0;
    padding: 0;
  }

  #tokenExIframeDiv iframe {
    width: 100% !important;
    transform: none;
  }

  .form-group {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }

  .creditcardLabel {
    font-size: 0.9rem;
  }

  /* Add padding bottom to card number container */
  .cardnumber-group {
    padding-bottom: 1.5rem !important;
  }

  /* Alternative selector if :has is not supported in all browsers */
  div[id="cardNumberLabel"] {
    padding-bottom: 1rem;
  }
}

@media screen and (max-width: 320px) {
  .paymentContainer {
    padding: 0.75em 0.25em;
  }

  .payment-form {
    padding: 4px 0.25rem !important;
  }
}

.form-group {
  position: relative;
  width: 100%;
  margin-bottom: 2rem !important;
}

.form-group.has-validation {
  margin-bottom: 2.5rem !important;
}

.form-control.is-invalid,
.form-control.is-valid {
  margin-bottom: 1.5rem;
}

.cardnumber-group {
  margin-bottom: 2.5rem !important;
}

.expiration-group {
  margin-bottom: 3rem !important;
}

.cvv-group {
  margin-bottom: 3.5rem !important;
}

.expiration-date-container {
  gap: 0.5rem;
  margin-bottom: 0;
}

.form-check {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:valid ~ .valid-feedback,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-valid ~ .valid-feedback {
  display: block;
  margin-bottom: 1.5rem;
}

.position-relative {
  position: relative !important;
}

.mb-3 {
  margin-bottom: 0 !important;
}

.invalid-feedback,
.valid-feedback {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: 4px;
  font-size: 0.875em;
  z-index: 1;
}

.invalid-feedback {
  color: #dc3545;
}

.valid-feedback {
  color: #198754;
}

.form-control:valid,
.form-control:invalid,
.form-control.is-valid,
.form-control.is-invalid {
  background-image: none !important;
}

/* Payment specific styles */
.paymentContainer {
  overflow-x: hidden;
  width: 100%;
  padding: 1.1428571429em;
  box-sizing: border-box;
}

/* Form groups in Payment */
.paymentContainer .form-group {
  position: relative;
  width: 100%;
  margin-bottom: 2.5rem !important;
}

/* Card name field */
.paymentContainer .form-group:has(.creditcardLabel:contains("NAME ON CARD")) {
  margin-bottom: 2rem !important;
}

/* Card number field */
.paymentContainer .cardnumber-group {
  margin-bottom: 2.5rem !important;
}

/* Expiration date group */
.paymentContainer .expiration-group {
  margin-bottom: 2.5rem !important;
}

.paymentContainer .expiration-date-container {
  gap: 0.5rem;
  margin-bottom: 1rem;
}

/* CVV group */
.paymentContainer .cvv-group {
  margin-bottom: 3rem !important;
}

/* Feedback messages in Payment */
.paymentContainer .invalid-feedback,
.paymentContainer .valid-feedback {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: 4px;
  font-size: 0.875em;
  z-index: 1;
}

.paymentContainer .invalid-feedback {
  color: #dc3545;
}

.paymentContainer .valid-feedback {
  color: #198754;
}

/* Checkboxes in Payment */
.paymentContainer .form-check {
  position: relative;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

/* Labels in Payment */
.paymentContainer .creditcardLabel {
  font-weight: bold;
  color: #434343;
  margin-bottom: 0.5rem;
}

/* Input fields in Payment */
.paymentContainer .payment-form {
  height: 40px;
  padding: 8px 12px;
}

/* Show validation messages in Payment */
.paymentContainer .was-validated .form-control:invalid ~ .invalid-feedback,
.paymentContainer .was-validated .form-control:valid ~ .valid-feedback,
.paymentContainer .form-control.is-invalid ~ .invalid-feedback,
.paymentContainer .form-control.is-valid ~ .valid-feedback {
  display: block;
}

/* Mobile adjustments for Payment */
@media screen and (max-width: 576px) {
  .paymentContainer {
    padding: 1em;
    max-width: 100vw;
  }

  .paymentContainer .form-group {
    max-width: 100%;
  }

  .paymentContainer .creditcardLabel {
    font-size: 0.9rem;
  }

  .paymentContainer .cardnumber-group {
    padding-bottom: 1.5rem !important;
  }
}

/* Keep existing styles for ShippingAddress unchanged */
/* Styles not within .paymentContainer will continue to work as before */

/* Payment form container */
.paymentContainer {
  overflow-x: hidden;
  width: 100%;
  padding: 1.1428571429em;
  box-sizing: border-box;
}

/* Name on card field styles */
.payment-name-group {
  position: relative;
  width: 100%;
  margin-bottom: 2rem !important;
}

.payment-name-group .form-control {
  height: 40px;
  padding: 8px 12px;
}

.payment-name-group .invalid-feedback,
.payment-name-group .valid-feedback {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 100%;
  font-size: 0.875em;
  z-index: 1;
}

.payment-name-group .invalid-feedback {
  color: #dc3545;
}

.payment-name-group .valid-feedback {
  color: #198754;
}

.payment-name-group .form-control.is-invalid {
  border-color: #dc3545;
  margin-bottom: 0;
}

.payment-name-group .form-control.is-valid {
  border-color: #198754;
  margin-bottom: 0;
}

/* Payment form labels */
.creditcardLabel {
  font-weight: bold;
  color: #434343;
  display: block;
  margin-bottom: 0.5rem;
}

/* Payment form groups spacing */
.payment-name-group {
  position: relative;
  width: 100%;
  margin-bottom: 2rem !important;
}

.cardnumber-group {
  margin-bottom: 1.5rem !important; /* Reduced space between card number and dates */
}

.expiration-group {
  margin-bottom: 2rem !important;
}

/* CVV group specific styles */
.cvv-group {
  position: relative;
  margin-bottom: 3rem !important;
}

.cvv-group .invalid-feedback,
.cvv-group .valid-feedback {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 100%;
  font-size: 0.875em;
  z-index: 1;
}

/* Expiration date container adjustments */
.expiration-date-container {
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.expiration-date-container .invalid-feedback,
.expiration-date-container .valid-feedback {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  font-size: 0.875em;
}

/* Mobile adjustments */
@media screen and (max-width: 576px) {
  .cvv-group {
    margin-bottom: 4rem !important; /* Extra space for error message in mobile */
  }
}

/* General form validation styles */
.form-control.is-invalid,
.form-control.is-valid {
  margin-bottom: 1.5rem;
}

/* Override margin for Payment form controls */
.paymentContainer .form-control.is-invalid,
.paymentContainer .form-control.is-valid {
  margin-bottom: 0;
}

/* Payment specific feedback messages positioning */
.paymentContainer .invalid-feedback,
.paymentContainer .valid-feedback {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: 4px;
  font-size: 0.875em;
  z-index: 1;
}

.product-title {
  max-width: 200px;
  padding-right: 15px !important;
  word-wrap: break-word;
  white-space: normal !important;
}

.productTable td:last-child {
  text-align: right;
  white-space: nowrap;
  min-width: 80px;
}

@media (max-width: 768px) {
  .product-title {
    max-width: 150px;
  }
}
